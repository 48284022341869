
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute, useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { DriverObject } from '@/store/modules/assets/DriversModule';
  import DriverPaymentsListing from './DriverPaymentsListing.vue';

  export default defineComponent({
    name: 'packages-overview',
    components: {
      DriverPaymentsListing,
    },

    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { can } = useAbility();
      const packages = ref();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text, options = {}) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('driverPayments'), [
          translate('assets'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_DRIVER_PAYMENTS,
        route.params.id
      );

      packages.value = data.data;

      const { data: driverData } = await store.dispatch(
        Actions.GET_DRIVER,
        route.params.id
      );

      const driver = ref<DriverObject>(driverData.data);

      const refreshPaymentsListing = async () => {
        await store.dispatch(Actions.GET_DRIVER_PAYMENTS, route.params.id);
      };

      await refreshPaymentsListing();

      const onSubmitCreate = async () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.SEND_PAYMENT_REQUEST, {
          driverId: route.params.id,
          reason: 'collect payment',
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: 'Total: ' + String(packages.value.total),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, continue',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-light-primary',
              cancelButton: 'btn btn-outline-danger ms-2',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                text: translate('SUCCESSFULLY_CREATED_DRIVER'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              }).then(() => {
                router.push({ name: 'drivers-listing' });
              });
            }
          });
        }
      };

      return {
        onSubmitCreate,
        goBack,
        refreshPaymentsListing,
        packages,
        translate,
        driver,
        can,
      };
    },
  });
