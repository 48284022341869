import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_7 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "d-flex flex-wrap py-5" }
const _hoisted_12 = { class: "flex-equal me-5" }
const _hoisted_13 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_14 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_15 = { class: "text-gray-800 min-w-200px" }
const _hoisted_16 = { class: "text-gray-400" }
const _hoisted_17 = { class: "text-gray-800" }
const _hoisted_18 = { class: "text-gray-400" }
const _hoisted_19 = { class: "text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DriverPaymentsListing = _resolveComponent("DriverPaymentsListing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.translate('driverDetails')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "btn btn-light btn-active-light-primary me-2",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                }, _toDisplayString(_ctx.translate('backToDriversList')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("table", _hoisted_13, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.translate('fullName')) + ": ", 1),
                    _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.driver.fullName), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.translate('email')) + ":", 1),
                    _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.driver.email), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.translate('phoneNumber')) + ":", 1),
                    _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.driver.mobile), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_DriverPaymentsListing, {
      "driver-data": _ctx.packages,
      onListChanged: _ctx.refreshPaymentsListing
    }, null, 8, ["driver-data", "onListChanged"])
  ], 64))
}