
  import { defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { useRouter, useRoute } from 'vue-router';

  export default defineComponent({
    name: 'payment-listing',
    props: {
      driverData: { type: Object, default: null },
    },
    components: {
      Datatable,
    },
    emit: ['list-changed'],
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const route = useRoute();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('trackNumber'),
          key: 'trackNumber',
          sortable: false,
        },
        {
          name: translate('reason'),
          key: 'reason',
          sortable: false,
        },
        {
          name: translate('amount'),
          key: 'amount',
          sortable: false,
        },
        {
          name: translate('date'),
          key: 'date',
          sortable: false,
        },
        {
          name: translate('collected'),
          key: 'collected',
          sortable: false,
        },
      ]);

      const onSubmitCreate = async () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: 'Total: ' + String(props.driverData.total),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, continue',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-light-primary',
              cancelButton: 'btn btn-outline-danger ms-2',
            },
            preConfirm: () => {
              if (props.driverData.total === 0) {
                Swal.showValidationMessage(
                  'Sorry, you cannot collect payments as the total amount due is 0.'
                );
              }
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (submitButton.value) {
                // eslint-disable-next-line
                submitButton.value!.disabled = true;
                submitButton.value.setAttribute('data-kt-indicator', 'on');
              }

              await store.dispatch(Actions.SEND_PAYMENT_REQUEST, {
                driverId: route.params.id,
                reason: 'collect payment',
              });

              if (submitButton.value) {
                submitButton.value.removeAttribute('data-kt-indicator');
                // eslint-disable-next-line
                submitButton.value!.disabled = false;
              }

              Swal.fire({
                text: translate('SUCCESSFULLY_COLLECTED_PAYMENTS'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: translate('ok'),
                customClass: {
                  confirmButton: 'btn btn-light-primary',
                },
              }).then(() => {
                location.reload();
              });
            }
          });
        }
      };

      return {
        tableHeader,
        onSubmitCreate,
        translate,
        can,
      };
    },
  });
