import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "collapse show" }
const _hoisted_7 = { class: "card-body border-top p-9" }
const _hoisted_8 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_9 = {
  key: 1,
  class: "badge badge-light-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('create', 'driver'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('paymentDetails')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-flex btn-light-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmitCreate && _ctx.onSubmitCreate(...args)))
            }, _toDisplayString(_ctx.translate('collectPayments')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Datatable, {
              "table-header": _ctx.tableHeader,
              "table-data": _ctx.driverData.data,
              "rows-per-page": 5,
              "enable-items-per-page-dropdown": false
            }, {
              "cell-trackNumber": _withCtx(({ row: payment }) => [
                _createTextVNode(_toDisplayString(payment.trackNumber), 1)
              ]),
              "cell-reason": _withCtx(({ row: payment }) => [
                _createTextVNode(_toDisplayString(payment.reason), 1)
              ]),
              "cell-amount": _withCtx(({ row: payment }) => [
                _createTextVNode(_toDisplayString(payment.amount), 1)
              ]),
              "cell-date": _withCtx(({ row: payment }) => [
                _createTextVNode(_toDisplayString(payment.date), 1)
              ]),
              "cell-collected": _withCtx(({ row: payment }) => [
                (payment.collected)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.translate('collected')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.translate('uncollected')), 1))
              ]),
              _: 1
            }, 8, ["table-header", "table-data"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}